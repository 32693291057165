export function formateTimeStamp (timeStamp) {
    var hour;
    var min;
    var seconds;
  
    hour = parseInt(timeStamp / (60 * 60)) // 计算整数小时数
    var afterHour = timeStamp - hour * 60 * 60 // 取得算出小时数后剩余的秒数
    min = parseInt(afterHour / 60) // 计算整数分
    seconds = parseInt(timeStamp - hour * 60 * 60 - min * 60) // 取得算出分后剩余的秒数
  
    if (hour < 10) {
      hour = '0' + hour
    }
  
    if (min < 10) {
      min = '0' + min;
    }
  
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
  
    const restTime = {
      hour: hour,
      min: min,
      seconds: seconds
    }
    return restTime
  }
